<template>
  <aLayoutHeader class="mobile-header">
    <aRow type="flex" justify="space-between">
      <aCol>
        <div>
          <router-link to="/" tag="span">
            <img
              class="ml-10"
              src="@/assets/images/haya-logo.png"
              alt="logo"
              width="130"
              style="position: relative; top: -3px; cursor: pointer"
            />
          </router-link>
        </div>
      </aCol>
      <aCol flex="180px">
        <aRow type="flex" justify="end">
          <aCol class="top-bar-item notifications" flex="60px">
            <a-popover
              :placement="
                $root.reponsive([
                  {
                    min: 0,
                    max: 960,
                    span: 'bottomRight',
                  },
                  {
                    min: 961,
                    max: 9999,
                    span: 'bottom',
                  },
                ])
              "
              trigger="click"
              width="300px"
              class="notifications"
            >
              <template slot="content">
                <ListNotifications @totalUnread="totalUnreadedNotifications" />
              </template>

              <template slot="title">
                <span>Notificações</span>
              </template>

              <div class="action">
                <a-badge :count="totalUnread">
                  <img src="@/assets/images/dashboard/sino-white.png" />
                </a-badge>
              </div>
            </a-popover>
          </aCol>
          <aCol @click="openMobileMenu = true" class="top-bar-item" flex="60px">
            <a-icon type="menu" />
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </aLayoutHeader>
</template>

<script>
import headerMixins from "./mixins/headerMixins";
import avatar from "../../../assets/images/dashboard/user-avatar.png";
import ListNotifications from "../../notifications/ListNotifications.vue";

export default {
  name: "SystemHeaderMobile",
  props: {
    tokenExpired: Boolean,
  },
  mixins: [headerMixins],
  components: { ListNotifications },
  data() {
    return {
      avatar,
    };
  },
  mounted() {
    this.getNotifications();
    setInterval(() => {
      this.getNotifications();
    }, 600000);
  },
};
</script>

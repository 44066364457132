var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aLayoutHeader',{staticClass:"mobile-header"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_c('div',[_c('router-link',{attrs:{"to":"/","tag":"span"}},[_c('img',{staticClass:"ml-10",staticStyle:{"position":"relative","top":"-3px","cursor":"pointer"},attrs:{"src":require("@/assets/images/haya-logo.png"),"alt":"logo","width":"130"}})])],1)]),_vm._v(" "),_c('aCol',{attrs:{"flex":"180px"}},[_c('aRow',{attrs:{"type":"flex","justify":"end"}},[_c('aCol',{staticClass:"top-bar-item notifications",attrs:{"flex":"60px"}},[_c('a-popover',{staticClass:"notifications",attrs:{"placement":_vm.$root.reponsive([
                {
                  min: 0,
                  max: 960,
                  span: 'bottomRight',
                },
                {
                  min: 961,
                  max: 9999,
                  span: 'bottom',
                },
              ]),"trigger":"click","width":"300px"}},[_c('template',{slot:"content"},[_c('ListNotifications',{on:{"totalUnread":_vm.totalUnreadedNotifications}})],1),_vm._v(" "),_c('template',{slot:"title"},[_c('span',[_vm._v("Notificações")])]),_vm._v(" "),_c('div',{staticClass:"action"},[_c('a-badge',{attrs:{"count":_vm.totalUnread}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/sino-white.png")}})])],1)],2)],1),_vm._v(" "),_c('aCol',{staticClass:"top-bar-item",attrs:{"flex":"60px"},on:{"click":function($event){_vm.openMobileMenu = true}}},[_c('a-icon',{attrs:{"type":"menu"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }